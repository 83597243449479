import React from 'react';
import { View, StyleSheet, ImageBackground, ScrollView } from 'react-native';
import { useClient } from '../providers/ClientProvider';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParams } from './Home';
import { useNav } from '../providers/NavProvider';
import { getObjectByPath } from '../services/Utils';
import { ISitee } from './ClientLanding';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/core';
import Header from './Header';
import LogProvider from '../components/Logs/LogProvider'
import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native-paper';
//import { ScrollView } from 'react-native-gesture-handler';
type Props = NativeStackScreenProps<RootStackParams, "List">

const ListLanding: React.FC<Props> = ({ route }) => {
    const { setCurrentClientShortURL, clientObj } = useClient();
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
    const { sitesResp, setCurrentLeafObj, currentLeafObj } = useNav();
    const { clientName } = useClient();
    const [listObj, setlistObj] = React.useState<ISitee>(null);

    useFocusEffect(() => {
        if (listObj) {
            setCurrentLeafObj(listObj);
        }
    });

    React.useEffect(() => {
        setCurrentClientShortURL(route.params.clientShortURL);
        if (sitesResp) {
            const tempListObj = getObjectByPath(decodeURIComponent(route.params.sitePath), sitesResp);
            if (tempListObj) {
                setlistObj(tempListObj);
            }
            else {
                navigation.replace('NotFoundSite', { clientShortURL: route.params.clientShortURL, sitePath: route.params.sitePath });
            }

        }
    }, [route && route.params && route.params.sitePath && route.params.sitePath, sitesResp]);

    return (
        <View>
            <View>
                <Header></Header>
            </View>
            {/* <ScrollView contentContainerStyle={{ flexGrow: 1 }}> */}
                <View style={styles.navWrapper}>
                    {(listObj && listObj.title) &&
                        <View style={{ backgroundColor: '#102B27', width: "100%", padding: 10, alignItems: 'center' }}>
                            <Text variant='titleLarge' style={{ color: '#fff' }} >{listObj.title}</Text>
                        </View>
                    }
                    <View style={styles.container}>

                        {(listObj) &&
                            //<LinearGradient colors={['#2980B9', '#6DD5FA', '#FFFFFF']} >
                            // <LinearGradient colors={['#102B27', '#676767', '#FFFFFF']} >
                            <LinearGradient colors={['#e9f9ef', '#FFF', '#FFF']} >
                                {/* <LinearGradient colors={['#DEE4EA',#beecd0, '#F9FCFF']} > */}
                                <View>

                                    {/* <View style={styles.logContainer}>
                                        <Text style={styles.logTitle}>{listObj.title}</Text>
                                    </View> */}
                                    {
                                        LogProvider ?
                                            <LogProvider logTitle={listObj.title} parentId={listObj.parentId} Permissions={JSON.stringify(listObj.permissions && listObj.permissions).toLowerCase()} listObj={listObj} />
                                            :
                                            null
                                    }
                                </View>
                            </LinearGradient>
                        }
                    </View>
                </View>
            {/* </ScrollView> */}
        </View>
    )
}

const styles = StyleSheet.create({
    navWrapper: {},
    container: {
        //flex: 1,
        // padding: 16,
        // gap: 20,
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        //alignItems: 'center',
        // justifyContent: 'space-evenly',
       // paddingLeft: 10,
       // paddingRight: 10,
        //maxWidth:1300,
        //alignSelf:'center',
        

    },
    // card: {
    //     minWidth: 300,
    //     marginVertical: 8
    // },
    // logContainer: {
    //     //width: '100%',
    //     //height: '100%',
    //     //alignContent:'center',
    //     //alignItems:'center'
    //     alignItems: 'center',
       
    // },
    // logTitle: {
    //     fontWeight: '900',
    //     color: '#FFFFFF',//'#ffcf1a'
    // },
    // footer: {
    //     height: 100
    // }
});

export default ListLanding
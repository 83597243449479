import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, Pressable } from 'react-native';
import { logStyles } from '../../LogControllers/LogStyles';
import Windrose from '../charts/fenceline_windroseChart';
interface Props {
    data: any;
    sixMonthData?: any;
    threshold?: number;
}
interface StationData {
    stationName: string;
    recordedDate: string;
    windSpeed: number;
    windDirection: number
}
interface WindData {
    windSpeed: number;
    windDirection: number;
}

interface SpeedRange {
    start: number;
    end: number;
}

interface DirectionBin {
    label: string;
    start: number;
    end: number;
}
interface BinnedData {
    [key: string]: number[];
}
export default function FencelineWind(props: Props) {
    const [dailyData, setDailyData] = useState<StationData[]>([]);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        try {
            setLoading(true);
            const data = props.data//await GetFencelineDailyData(accessToken, clientAPIURL, formattedStartDate, formattedEndDate);
            setDailyData(data);

        } catch (error) {
            console.error('Error in processing PM10 data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch initial data on component mount
    }, []);

    function transformWindData(
        data: WindData[],
        speedRanges: SpeedRange[],
        directionBins: DirectionBin[]
    ): BinnedData {
        const binnedData: BinnedData = {};

        directionBins.forEach((bin) => {
            binnedData[bin.label] = Array(speedRanges.length).fill(0);
        });

        data.forEach(({ windSpeed, windDirection }) => {
            // Determine direction bin
            const directionBin = directionBins.find(
                (bin) => windDirection >= bin.start && windDirection < bin.end
            );

            if (!directionBin) return;

            // Determine speed range bin
            const speedIndex = speedRanges.findIndex(
                (range) => windSpeed >= range.start && windSpeed < range.end
            );

            if (speedIndex !== -1) {
                binnedData[directionBin.label][speedIndex]++;
            }
        });

        return binnedData;
    }


    // Example direction bins and speed ranges
    const directionBins = [
        { label: 'N', start: 348.75, end: 11.25 },
        { label: 'NNE', start: 11.25, end: 33.75 },
        { label: 'NE', start: 33.75, end: 56.25 },
        { label: 'ENE', start: 56.25, end: 78.75 },
        { label: 'E', start: 78.75, end: 101.25 },
        { label: 'ESE', start: 101.25, end: 123.75 },
        { label: 'SE', start: 123.75, end: 146.25 },
        { label: 'SSE', start: 146.25, end: 168.75 },
        { label: 'S', start: 168.75, end: 191.25 },
        { label: 'SSW', start: 191.25, end: 213.75 },
        { label: 'SW', start: 213.75, end: 236.25 },
        { label: 'WSW', start: 236.25, end: 258.75 },
        { label: 'W', start: 258.75, end: 281.25 },
        { label: 'WNW', start: 281.25, end: 303.75 },
        { label: 'NW', start: 303.75, end: 326.25 },
        { label: 'NNW', start: 326.25, end: 348.75 },
    ];
    

    const speedRanges = [
        { start: 0, end: 0.5, color: '#1f77b4' },
        { start: 0.5, end: 2, color: '#aec7e8' },
        { start: 2, end: 4, color: '#ff7f0e' },
        { start: 4, end: 6, color: '#2ca02c' },
        { start: 6, end: 8, color: '#98df8a' },
        { start: 8, end: 10, color: '#d62728' },
        { start: 10, end: 15, color: '#ff9896' },
      ];

    const transformedData = props.data && transformWindData(props.data, speedRanges, directionBins);

    return (
        <View>
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                <View>
                    <ScrollView contentContainerStyle={styles.container}>
                        {dailyData.length > 0 ? (
                            <View>
                                <ScrollView style={styles.container} contentContainerStyle={logStyles.scrollViewContentContainer}>
                                    <Text style={styles.title}>Wind Speed vs Direction</Text>
                                    <View style={[logStyles.fencelineCard, { backgroundColor: '' }]}>
                                        {transformedData &&
                                            <Windrose
                                                data={transformedData}
                                                directionBins={directionBins}
                                                speedRanges={speedRanges}
                                            />}
                                    </View>
                                </ScrollView>
                            </View>
                        ) : (
                            <Text style={styles.noDataText}>
                                {loading ? 'Loading data...' : 'No data available for the selected range.'}
                            </Text>
                        )}
                    </ScrollView>
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    rowContainer: {
        flexDirection: 'row', // Horizontal layout
        flexWrap: 'wrap', // Allow wrapping for smaller screens
        justifyContent: 'space-between', // Add space between charts
        paddingHorizontal: 10, // Add some padding
    },
    chartContainer: {
        flex: 1, // Each chart takes half the screen width
        maxWidth: '48%', // Limit the maximum width of each chart to 48%
        marginBottom: 20, // Add space below in case of wrapping
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10,
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    flexItem: {
        flexBasis: '30%', // Adjust to control the size of each item in the row
        marginBottom: 8, // For smaller screens when wrapping
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    datePicker: {
        width: '100%',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    button: {
        borderRadius: 5,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#4BB75F',
    },
    buttonClose: {
        backgroundColor: '#4BB75F',
    },
    dataContainer: {
        marginTop: 16,
    },
    dataRow: {
        padding: 8,
        marginBottom: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
    },
    noDataText: {
        marginTop: 16,
        fontSize: 16,
        fontStyle: 'italic',
        color: '#999',
    },
});

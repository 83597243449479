import React, { useState } from "react"
import { StyleSheet, View, ScrollView } from 'react-native';
import NoiseLogScreen from "./LogControllers/noise_LogScreen";
import EnergyLogScreen from "./LogControllers/Energy_LogScreen";
import { SafeAreaView } from 'react-native-safe-area-context';
import WaterLogScreen from "./LogControllers/WaterLogScreen";
import ImportedSoilLogScreen from "./LogControllers/ImportedSoilLog";
import HazardousMaterialStorage from "./LogControllers/HazardousMaterialStorage";
import RecycledMaterial from "./LogControllers/RecycledMaterial";
import RegionalMaterial from "./LogControllers/RegionalMaterial";
import VibrationMonitoring from "./LogControllers/VibrationMonitoring";
import PM10Monitoring from "./LogControllers/PM10Monitoring";
import WasteWaterLog from "./LogControllers/WasteWaterLog";
import WasteLog from "./LogControllers/WasteLog";
import SpoilLog from "./LogControllers/SpoilLog";
import TrainingLog from "./LogControllers/TrainingLog";
import ObservationLog from "./LogControllers/ObservationLog";
import MonthlyWasteLog from "./LogControllers/MonthlyWasteLog";
import PermitRegister from "./LogControllers/PermitRegister";
import ReportRequest from "./LogControllers/ReportRequest";
import ProjectRecordsWithPagination from "./LogControllers/ProjectRecordsWithPagination";
import { ISitee } from "../ClientLanding";

interface Props {
    logTitle: string;
    parentId: string;
    Permissions?: string;
    listObj:ISitee;
}
const LogProvider: React.FC<Props> = (props) => {
    //const [containerstyles, setcontainerstyles] = useState(Platform.OS ==="web" ? styles.mobilecontainer : styles.pccontainer );
    switch (props.logTitle) {
        case 'Noise Monitoring Log':
            return (


                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <NoiseLogScreen siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Energy Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <EnergyLogScreen siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Water Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <WaterLogScreen siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Imported Soil Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <ImportedSoilLogScreen siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Hazardous Material Storage Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <HazardousMaterialStorage siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Recycled Materials Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <RecycledMaterial siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Regional Materials Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <RegionalMaterial siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Vibration Monitoring Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <VibrationMonitoring siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'PM10 Monitoring Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <PM10Monitoring siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Wastewater Tracking Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <WasteWaterLog siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Waste Tracking Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <WasteLog siteId={props.parentId} logTitle={props.logTitle} />
                        {/* <MonthlyWasteLog siteId={props.parentId} logTitle={props.logTitle} /> */}
                        {/* <ConsolWasteLog siteId={props.parentId} logTitle={props.logTitle} /> */}
                    </View>
                </View>

            )
        case 'Spoil Tracking Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <SpoilLog siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Training Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <TrainingLog siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Environmental Issues Observations':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <ObservationLog siteId={props.parentId} logTitle={props.logTitle} Permissions={props.Permissions} />
                    </View>
                </View>

            )
        case 'Permit Register':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <PermitRegister siteId={props.parentId} logTitle={props.logTitle} />
                    </View>
                </View>

            )
        case 'Monthly Waste Log':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <MonthlyWasteLog siteId={props.parentId} logTitle={'Waste Tracking Log'} />
                    </View>
                </View>

            )
        case 'Report Request':
            return (

                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <ReportRequest siteId={props.parentId} logTitle={'Report Request'} />
                    </View>
                </View>

            )
        case 'Project Records':
            return (
                <View style={styles.container}>
                    <View style={styles.logBackground} >
                        <ProjectRecordsWithPagination siteId={props.parentId} logTitle={'Project Records'} listObj={props.listObj} />
                    </View>
                </View>
            )
        default:
            return (
                null
            )
    }

}
const styles = StyleSheet.create({
    container: {

        //flex: 1,
        // padding: 16,
        //gap: 20,
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        // alignItems: 'flex-start',
        // justifyContent: 'flex-start',
        //backgroundColor: 'aliceblue',
        //backgroundColor: 'rgba(255, 255, 255, 0.5)',
        //minHeight:650,//tempfix for tabs
        // backgroundColor:"red",
    },
    logBackground: {
        //backgroundColor: 'rgba(255, 255, 255, 1)', 
        // flex: 1 
    },
    // pccontainer: {
    //     marginTop: 8,
    //     //backgroundColor: 'aliceblue',
    //     backgroundColor: 'rgba(255, 255, 255, 0.5)',
    //     paddingLeft: '10%',
    //     paddingRight: '10%',
    //     height: 'auto',
    // },
    // mobilecontainer: {
    //     marginTop: 8,
    //     //backgroundColor: 'aliceblue',
    //     backgroundColor: 'rgba(255, 255, 255, 0.5)',
    //     paddingLeft: '10%',
    //     paddingRight: '10%',
    //     height: 'auto',
    // },
});
export default LogProvider;
import React, { useState } from "react";
import { Modal, View, Text, TextInput, TouchableOpacity, StyleSheet } from "react-native";
import { Checkbox } from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";

export default function ProjectRecordUploadModal({
  visible,
  onClose,
  onSubmit,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: (file: any, overwrite: boolean) => void;
}) {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [overwrite, setOverwrite] = useState<boolean>(false);

  const handleFilePick = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        copyToCacheDirectory: true,
      });

      if (result.canceled || !result.assets) {
        alert("No file selected.");
        return;
      }

      setSelectedFile(result.assets[0]);
    } catch (error) {
      console.error("Error selecting file:", error);
    }
  };

  const handleSubmit = () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    onSubmit(selectedFile, overwrite);
    onClose(); // Close the modal after submitting
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={styles.overlay}>
        <View style={styles.modalContainer}>
          <Text style={styles.modalTitle}>Upload File</Text>

          {/* File Picker */}
          <TouchableOpacity onPress={handleFilePick} style={styles.filePicker}>
            <Text>{selectedFile ? selectedFile.name : "Select File"}</Text>
          </TouchableOpacity>

          {/* Overwrite Checkbox */}
          <View style={styles.checkboxContainer}>
            <Checkbox
                //status={overwrite}
                status={overwrite ? "checked" : "unchecked"}
                onPress={(value:any) => {setOverwrite(!overwrite); console.log(value)}}
                uncheckedColor="#666"
                color="#007BFF"
            />
            
            <Text>Overwrite if file exists</Text>
          </View>

          {/* Buttons */}
          <View style={styles.buttonRow}>
            <TouchableOpacity onPress={handleSubmit} style={styles.submitButton}>
              <Text style={styles.buttonText}>Submit</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={onClose} style={styles.cancelButton}>
              <Text style={styles.buttonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    width: "80%",
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 20,
  },
  filePicker: {
    width: "100%",
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    marginBottom: 20,
    alignItems: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  buttonRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  submitButton: {
    backgroundColor: "#007BFF",
    padding: 10,
    borderRadius: 5,
    flex: 1,
    marginRight: 10,
    alignItems: "center",
  },
  cancelButton: {
    backgroundColor: "#ccc",
    padding: 10,
    borderRadius: 5,
    flex: 1,
    marginLeft: 10,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
});

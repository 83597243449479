import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Circle, G, Line, Polygon, Rect, Text } from 'react-native-svg';
import ChartStyles from './chartStyles';
import Legend from './Legend';

interface WindroseProps {
  data: Record<string, number[]>;
  directionBins: { label: string; start: number; end: number }[];
  speedRanges: { start: number; end: number; color: string }[];
}

export default function Windrose({ data, directionBins, speedRanges }: WindroseProps) {
  const [tooltip, setTooltip] = useState<{ x: number; y: number; value: string } | null>(null);
  const width = 400; // Adjusted for better visualization
  const height = 400;
  const radius = 150;
  const centerX = width / 2;
  const centerY = height / 2;

  const maxFrequency = Math.max(...Object.values(data).flat());
  const normalizedData = Object.entries(data).reduce((acc: any, [key, values]) => {
    acc[key] = values.map((value) => (value / maxFrequency) * 100); // Normalize to percentage
    return acc;
  }, {});
  const handlePress = (x: number, y: number, value: string) => {
    setTooltip({ x, y, value });
  };


  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Svg height={centerY * 2} width={centerX * 2}>
        {/* Draw concentric circles */}
        {speedRanges.map((_, index) => (
          <Circle
            key={index}
            cx={centerX}
            cy={centerY}
            r={((index + 1) / speedRanges.length) * radius}
            stroke="gray"
            strokeWidth={0.5}
            fill="none"
          />
        ))}

        {/* Draw radial lines and labels for directions */}
        {directionBins.map((bin, index) => {
          const angle = ((index / directionBins.length) * 2 * Math.PI) - Math.PI / 2; // Start at the top (North)
          const x = centerX + Math.cos(angle) * radius;
          const y = centerY + Math.sin(angle) * radius;

          // Position for labels slightly beyond the radius
          const labelX = centerX + Math.cos(angle) * (radius + 15);
          const labelY = centerY + Math.sin(angle) * (radius + 15);

          return (
            <React.Fragment key={index}>
              <Line
                x1={centerX}
                y1={centerY}
                x2={x}
                y2={y}
                stroke="gray"
                strokeWidth={0.5}
              />
              <Text
                x={labelX}
                y={labelY}
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize={10}
                fill="black"
              >
                {bin.label}
              </Text>
            </React.Fragment>
          );
        })}

        {/* Draw windrose segments */}
        {directionBins.map((bin, dirIndex) => {
          const startAngle = ((dirIndex / directionBins.length) * 2 * Math.PI) - Math.PI / 2;
          const endAngle = (((dirIndex + 1) / directionBins.length) * 2 * Math.PI) - Math.PI / 2;

          let cumulativeRadius = 0;

          return speedRanges.map((range, speedIndex) => {
            const frequency = data[bin.label][speedIndex];
            const normalizedFrequency = (frequency / maxFrequency) * radius;

            const segmentRadiusStart = cumulativeRadius;
            const segmentRadiusEnd = cumulativeRadius + normalizedFrequency;
            cumulativeRadius = segmentRadiusEnd;

            const adjustedRadiusStart = Math.min(segmentRadiusStart, radius);
            const adjustedRadiusEnd = Math.min(segmentRadiusEnd, radius);

            const points = [
              {
                x: centerX + Math.cos(startAngle) * adjustedRadiusStart,
                y: centerY + Math.sin(startAngle) * adjustedRadiusStart,
              },
              {
                x: centerX + Math.cos(endAngle) * adjustedRadiusStart,
                y: centerY + Math.sin(endAngle) * adjustedRadiusStart,
              },
              {
                x: centerX + Math.cos(endAngle) * adjustedRadiusEnd,
                y: centerY + Math.sin(endAngle) * adjustedRadiusEnd,
              },
              {
                x: centerX + Math.cos(startAngle) * adjustedRadiusEnd,
                y: centerY + Math.sin(startAngle) * adjustedRadiusEnd,
              },
            ];
            const tooltipValue = `${bin.label}, Speed ${range.start}-${range.end} m/s, ${frequency} occurrences`;
            return (
              // <G
              //   key={`${dirIndex}-${speedIndex}`}
              //   onPress={() => handlePress((points[2].x + points[0].x) / 2, (points[2].y + points[0].y) / 2, tooltipValue)}
              // >
              //   <Polygon
              //     key={`${dirIndex}-${speedIndex}`}
              //     points={points.map((p) => `${p.x},${p.y}`).join(' ')}
              //     fill={range.color}
              //     stroke="white"
              //     strokeWidth={0.5}
              //     //onPress={() => handlePress((points[2].x + points[0].x) / 2, (points[2].y + points[0].y) / 2, tooltipValue)}
              //   />
              // </G>
              <React.Fragment key={`${dirIndex}-${speedIndex}`}>
                <Polygon
                  points={points.map((p) => `${p.x},${p.y}`).join(' ')}
                  fill={range.color}
                  stroke="white"
                  strokeWidth={0.5}
                />
                <Rect
                  x={Math.min(points[0].x, points[1].x)}
                  y={Math.min(points[0].y, points[3].y)}
                  width={Math.abs(points[2].x - points[0].x)}
                  height={Math.abs(points[2].y - points[0].y)}
                  fill="transparent"
                  onPress={() => handlePress((points[2].x + points[0].x) / 2, (points[2].y + points[0].y) / 2, tooltipValue)}
                />
              </React.Fragment>
            );
          });
        })}
        {/* Tooltip */}
        {tooltip && (
          <Text
            x={tooltip.x}
            y={tooltip.y - 10}
            textAnchor="middle"
            alignmentBaseline="middle"
            fontSize={12}
            fill="black"
          >
            {tooltip.value}
          </Text>
        )}
      </Svg>

      {/* Legend */}
      <View style={styles.legend}>
        {speedRanges.map((range, index) => (
          <View key={index} style={styles.legendItem}>
            <View>
              <Legend fontColor={ChartStyles.chartTitle.color} key={index} name={`${range.start}-${range.end} m/s`} color={range.color} />
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  legend: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 10,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 5,
  },
  colorBox: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  legendText: {
    fontSize: 12,
  },
});
import React, { useState, useEffect } from "react";
import * as DocumentPicker from "expo-document-picker";
import { View, Text, FlatList, TouchableOpacity, StyleSheet, ActivityIndicator, TextInput, ScrollView, Platform } from "react-native";
import { Checkbox } from "react-native-paper";
import ConfirmBox from "../../Common/ConfirmBox";
import { useClient } from "../../../providers/ClientProvider";
import { getData, createRecord, deleteRecord, updateRecord, getRecordById } from "../common/service/ProjectRecords";
import { useAuth } from "../../../providers/AuthProvider";
import CreateFolderModal from "./ProjectRecordFolderModal";
import ProjectRecordUploadModal from "./ProjectRecordUploadModal";

type FolderItem = {
  name: string;
  type: "folder" | "file" | "library";
  id?: number;
  created?: string;
  modified?: string;
  deleted: number; // New property to track soft deletion status
  children?: FolderItem[]; // Only folders have children
  parentId: any,
  blobId?: string;
  listId?: string;
};
type ProjectRecord = {
  name: string;
  type: "folder" | "file" | "library";
  id?: number;
  created?: string;
  modified?: string;
  deleted: number; // New property to track soft deletion status
  children?: FolderItem[]; // Only folders have children
  parentId: any,
  blobId?: string;
  listId?: string;
};
export default function ProjectRecordsWithPagination(props: any) {
  const { getAccessToken } = useAuth();
  const { clientAPIURL } = useClient();
  const [currentListId, setListId] = useState("");
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showUpdateFolderModal, setUpdateShowFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [breadcrumbPath, setBreadcrumbPath] = useState<FolderItem[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentFolder, setCurrentFolder] = useState<FolderItem | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleUploadClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  function buildHierarchy(flatData: ProjectRecord[]) {
    // Create a map to hold references to each item by its ID
    const map = new Map();
    let rootItem: ProjectRecord | null = null;
    // First pass: Initialize all items in the map with their children arrays
    flatData.forEach((item: ProjectRecord) => {
      map.set(item.id, { ...item, children: [] });
      if (item.type === 'library') {
        rootItem = map.get(item.id); // Set the root item
      }
    });
    if (!rootItem) {
      throw new Error("No root item found with type 'library'.");
    }
    // Second pass: Assign children to their respective parents
    flatData.forEach((item: ProjectRecord) => {
      if (item.parentId && item.type !== 'library') {
        const parent = map.get(item.parentId);
        if (parent) {
          parent.children.push(map.get(item.id));
        } else {
          console.warn(`Parent with ID ${item.parentId} not found for item`, item);
        }
      }
    });
    return map.get(rootItem.id); // Return the root item with its complete hierarchy
  }

  useEffect(() => {
    if (currentFolder) {
      setBreadcrumbPath([currentFolder]);
    }
  }, [currentFolder]);
  useEffect(() => {
    if (breadcrumbPath.length > 0) {
      loadSortedData(1); // Load data whenever breadcrumbPath changes
    }
  }, [breadcrumbPath]);
  //const selectedFolder = breadcrumbPath[breadcrumbPath.length - 1];
  const [visibleItems, setVisibleItems] = useState([]); // Items to display
  const [page, setPage] = useState(1); // Current page
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMore, setHasMore] = useState(true); // To check if more data is available
  const [sortConfig, setSortConfig] = useState({
    column: "dateModified", // Default sorting column
    order: "desc", // Default sorting order
  });
  const [selectedItems, setSelectedItems] = useState<string[]>([]); // Store selected file IDs
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const PAGE_SIZE = 20;

  const [records, setRecords] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadRecords = async () => {
      if (props.listObj?.title === "Project Records") {
        setListId(props.listObj.id);
        if (props.listObj.id) await fetchRecords(props.listObj.id);
      }
    };
    loadRecords();
  }, [props.listObj]);

  const fetchRecords = async (oListId: string) => {
    setLoading(true);
    setError("");
    try {
      const endpoint = `${clientAPIURL}api/ProjectRecords/${oListId}`
      const accessToken = await getAccessToken();
      const response = await getData(endpoint, accessToken)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${(await response).status}`);
      }
      const flatData = await response.json();
      const hierarchicalData = buildHierarchy(flatData);
      setRecords(flatData); // Set retrieved records to the state
      if (hierarchicalData.children.length > 0) {
        setCurrentFolder(hierarchicalData);
      }
    } catch (err) {
      setError("Failed to fetch project records.");
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };
  const _loadSortedData = (newPage: number) => {
    const selectedFolder = breadcrumbPath[breadcrumbPath.length - 1];
    const sortedChildren = sortData(
      selectedFolder.children?.filter((child) => !child.deleted) || []
    );
    const startIndex = (newPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const nextItems = sortedChildren.slice(startIndex, endIndex);
    if (newPage === 1) {
      setVisibleItems(nextItems); // Reset visible items for new sort
    } else {
      setVisibleItems((prevItems) => [...prevItems, ...nextItems]);
    }
    setPage(newPage);
    setHasMore(nextItems.length === PAGE_SIZE); // Check if more data is available
    setLoading(false);
  };

  const loadSortedData = (newPage: number) => {
    const selectedFolder = breadcrumbPath[breadcrumbPath.length - 1];

    // Filter out deleted items
    const filteredChildren =
      selectedFolder.children?.filter((child) => child.deleted !== 1) || [];

    // Sort the filtered children based on the sortConfig
    const sortedChildren = sortData(selectedFolder.children || []);

    // Paginate the sorted data
    const startIndex = (newPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const nextItems = sortedChildren.slice(startIndex, endIndex);

    if (newPage === 1) {
      setVisibleItems(nextItems); // Reset visible items for new data
    } else {
      setVisibleItems((prevItems) => [...prevItems, ...nextItems]);
    }

    setPage(newPage);
    setHasMore(nextItems.length === PAGE_SIZE); // Check if more data is available
    setLoading(false);
  };


  const navigateToBreadcrumb = (index: number) => {
    setBreadcrumbPath(breadcrumbPath.slice(0, index + 1));
    setPage(1);
    setHasMore(true);
  };

  const openFolder = (folder: any) => {
    if (folder.type === "folder") {
      setBreadcrumbPath([...breadcrumbPath, folder]);
      setPage(1);
      setHasMore(true);
    } else {
      alert(`Opening file: ${folder.name}`);
    }
  };

  const handleSort = (column: any) => {
    setSortConfig((prev) => ({
      column,
      order: prev.column === column && prev.order === "asc" ? "desc" : "asc",
    }));
    setPage(1); // Reset to first page after sorting
  };

  const _sortData = (data: any) => {
    const sorted = [...data];
    const { column, order } = sortConfig;

    sorted.sort((a, b) => {
      if (column === "name") {
        return order === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (column === "dateCreated" || column === "dateModified") {
        return order === "asc"
          ? new Date(a[column]).getTime() - new Date(b[column]).getTime()
          : new Date(b[column]).getTime() - new Date(a[column]).getTime();
      }
      return 0;
    });

    return sorted;
  };

  const sortData = (data: any) => {
    if (!Array.isArray(data)) return [];
    if (!sortConfig.column) return data; // If no sorting column, return data as is

    return [...data].sort((a, b) => {
      const column = sortConfig.column;
      const order = sortConfig.order === "asc" ? 1 : -1;

      if (a[column] < b[column]) return -1 * order;
      if (a[column] > b[column]) return 1 * order;
      return 0;
    });
  };
  const loadMoreItems = () => {
    if (loading || !hasMore) return;

    setLoading(true);
    loadSortedData(page + 1); // Load next page of sorted data
  };

  const createNewFolder = async () => {
    if (!newFolderName.trim()) {
      alert("Error: " + "Folder name cannot be empty.");
      return;
    }
    const currentFolderId: number =
      breadcrumbPath[breadcrumbPath.length - 1]?.id || null;

    if (!currentFolderId || isNaN(Number(currentFolderId))) {
      alert("Error: Something went wrong. Unable to determine the parent folder.");
      return;
    }

    const newFolder: ProjectRecord = {
      "name": newFolderName,
      "type": "folder",
      //"dateCreated": new Date().toISOString(),
      //"dateModified": new Date().toISOString(),
      "deleted": 0,
      "listId": props.listObj.id,
      "parentId": currentFolderId
    };
    debugger
    //const endpoint = `${'https://localhost:44346/'}api/ProjectRecords`
    const endpoint = `${clientAPIURL}api/ProjectRecords`
    const accessToken = await getAccessToken();
    const response = await createRecord(endpoint, accessToken, newFolder)
    if (response.ok) {
      if (props.listObj.id) {
        await fetchRecords(props.listObj.id);
        alert("Success: " + `Folder "${newFolderName}" created.`);
      }

    }


    const updatedBreadcrumbPath = [...breadcrumbPath];
    const selectedFolder = updatedBreadcrumbPath[breadcrumbPath.length - 1];
    selectedFolder.children = [...(selectedFolder.children || []), newFolder];

    setBreadcrumbPath(updatedBreadcrumbPath); // Update breadcrumb path
    setNewFolderName(""); // Clear input
    setShowFolderModal(false);

  };

  const openEditFolderModal = () => {
    if (selectedItems.length !== 1) {
      alert("Please select a single folder to edit.");
      return;
    }
    const selectedItem = visibleItems.find(
      (item) => item.id === selectedItems[0]
    );
    if (!selectedItem) {
      alert("Selected item not found.");
      return;
    }

    // Check if the selected item is a file
    if (selectedItem.type === "file") {
      const fileNameParts = selectedItem.name.split(".");
      if (fileNameParts.length > 1) {
        const extension = fileNameParts.pop(); // Extract the extension
        const nameWithoutExtension = fileNameParts.join("."); // Handle names with multiple dots
        setNewFolderName(nameWithoutExtension); // Prepopulate only the name
        setFileExtension(extension); // Store the extension in state
      } else {
        // If no extension, treat it as a folder-like name
        setNewFolderName(selectedItem.name);
        setFileExtension(""); // No extension
      }
    } else {
      // If it's a folder, directly set the name
      setNewFolderName(selectedItem.name);
      setFileExtension(""); // No extension
    }

    setIsEditing(true);
    setShowFolderModal(true);
  };


  const updateFolder = async () => {
    if (!parseInt(selectedItems[0])) {
      alert("Please ensure to select a valid item.");
      return;
    }

    if (!newFolderName.trim()) {
      alert("Name cannot be empty.");
      return;
    }

    const currentFolderId: number =
      breadcrumbPath[breadcrumbPath.length - 1]?.id || null;

    // Combine the name and extension if it's a file
    const updatedName =
      fileExtension && fileExtension.trim()
        ? `${newFolderName.trim()}.${fileExtension.trim()}`
        : newFolderName.trim();

    const updatedItem: ProjectRecord = {
      id: parseInt(selectedItems[0]), // Assuming selectedItems[0] contains the ID of the selected item
      name: updatedName, // Use the updated name
      type: visibleItems.find((item) => item.id === selectedItems[0]).type, // Keep the original type
      listId: currentListId, // List ID from props
      parentId: currentFolderId, // Current folder ID
      deleted: 0, // Retain the deleted flag
    };

    try {
      //const endpoint = `${'https://localhost:44346/'}api/ProjectRecords/${selectedItems[0]}`;
      const endpoint = `${clientAPIURL}api/ProjectRecords/${selectedItems[0]}`;
      const accessToken = await getAccessToken(); // Function to fetch the access token
      const response = await updateRecord(endpoint, accessToken, updatedItem); // Make the API call

      if (response.ok) {
        // Update local state if the API call was successful
        if (currentListId) {
          await fetchRecords(currentListId);
        }
        setSelectedItems([]); // Clear selection
        setNewFolderName(""); // Reset input
        setFileExtension(""); // Reset extension
        setShowFolderModal(false); // Close modal
        alert("Item updated successfully.");
      } else {
        throw new Error(`Failed to update item: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error updating item:", error);
      alert("Error: Failed to update item. Please try again.");
    }
  };


  const handleFileUpload = async (doc: any, overwrite: boolean) => {
    try {
      // const result = await DocumentPicker.getDocumentAsync({
      //   type: "*/*",
      //   copyToCacheDirectory: true,
      // });

      // if (result.canceled || !result.assets) {
      //   alert("No file selected.");
      //   return;
      // }

      // const doc: any = result.assets[0];
      const currentFolderId = breadcrumbPath[breadcrumbPath.length - 1]?.id || null;

      if (!currentFolderId || isNaN(Number(currentFolderId))) {
        alert("Error: Unable to determine the parent folder.");
        return;
      }
      console.log("File:", doc);
      console.log("Overwrite:", overwrite);
      // const existingFiles = visibleItems.filter(
      //   (item) => item.parentId === currentFolderId && item.type === "file"
      // );
      const existingFile = visibleItems.find(
        (item) =>
          item.parentId === breadcrumbPath[breadcrumbPath.length - 1]?.id &&
          item.name === doc.name
      );

      // Step 3: Check for a file with the same name
      //const existingFile = existingFiles.find((file) => file.name === doc.name);
      const blobId = existingFile?.blobId; // Get blobId if file exists
      //const overwrite = !!blobId;

      var myHeaders = new Headers();
      const accessToken = await getAccessToken();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      var formdata = new FormData();
      let xhr = new XMLHttpRequest();
      debugger;
      if (Platform.OS === "web") {
        // Web-specific file append
        formdata.append("file", doc.file, doc.name);
      } else if (Platform.OS === "ios") {
        // iOS-specific file append
        formdata.append("file", doc);
        myHeaders.append("Content-Type", "multipart/form-data");
      } else {
        // Android-specific file append
        formdata.append("file", {
          uri: doc.uri,
          type: doc.mimeType || "application/octet-stream",
          name: doc.name,
        } as any);
      }

      // Add additional properties
      formdata.append("name", doc.name); // File name
      formdata.append("type", "file"); // File type
      formdata.append("parentId", currentFolderId.toString()); // Parent folder ID (ensure it's string)
      formdata.append("listId", currentListId); // Project/List ID
      if (blobId) formdata.append("blobId", blobId);
      formdata.append("overwrite", overwrite.toString());

      var requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      try {
        //const endpoint = `${'https://localhost:44346/'}api/ProjectRecords/upload`;
        const endpoint = `${clientAPIURL}api/ProjectRecords/upload`;
        xhr.open("POST", endpoint);
        xhr.setRequestHeader("Authorization", "Bearer " + accessToken);

        // Send the FormData, which includes file and properties
        xhr.send(formdata);

        xhr.onload = function () {
          if (xhr.status !== 200) {
            console.log(`Error ${xhr.status}: ${xhr.statusText}`);

          } else {
            console.log(`Done, got ${xhr.response}`);
            alert("File uploaded successfully!");
            if (props.listObj.id) {
              fetchRecords(props.listObj.id);
            }
          }
        };

        xhr.onerror = function () {
          console.error("Request failed", xhr.status, xhr.statusText, xhr.responseText);
        };
      } catch (ex) {
        console.error("Error during upload:", ex);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    }
  };

  const _handleFileUpload = async () => {
    try {
      debugger;
      // Step 1: Pick a file
      // const result = await DocumentPicker.getDocumentAsync({
      //   type: "*/*", // Allows all file types
      //   copyToCacheDirectory: true, // Saves the file temporarily
      // });
      let result: any = await DocumentPicker.getDocumentAsync({ multiple: false });
      if (result.canceled || !result.assets) {
        alert("No file selected.");
        return;
      }

      const file = result.assets[0]; // Get the first selected file
      const { uri, name, mimeType, size } = file;
      const currentFolderId: any =
        breadcrumbPath[breadcrumbPath.length - 1]?.id || null;

      if (!currentFolderId || isNaN(Number(currentFolderId))) {
        alert("Error: Something went wrong. Unable to determine the parent folder.");
        return;
      }

      // Step 2: Prepare FormData to send file and properties
      const formData = new FormData();
      formData.append("file", {
        uri,                // File URI
        name,               // File name
        type: mimeType || "application/octet-stream", // MIME type
      } as any);
      formData.append("name", name); // Custom property for file name
      formData.append("type", "file"); // File type
      formData.append("parentId", currentFolderId); // Parent folder ID
      formData.append("listId", currentListId); // Current project/list ID

      // Step 3: Send to API
      //const endpoint = `${'https://localhost:44346/'}api/ProjectRecords/upload`;
      const endpoint = `${clientAPIURL}api/ProjectRecords/upload`;
      const accessToken = await getAccessToken(); // Retrieve access token if needed

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include auth header if required
        },
        body: formData,
      });

      // Step 4: Handle Response
      if (response.ok) {
        const data = await response.json();
        alert(`File uploaded successfully! Blob ID: ${data.blobId}`);
      } else {
        throw new Error(`Failed to upload file. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    }
  };



  const toggleSelection = (itemId: string) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId)); // Unselect item
    } else {
      setSelectedItems([...selectedItems, itemId]); // Select item
    }
  };

  function markFolderAndChildrenDeleted(items: any) {
    return items.map((item: FolderItem) => {
      if (item.type === "folder" && item.children) {
        return {
          ...item,
          deleted: true,
          children: markFolderAndChildrenDeleted(item.children), // Recursively mark children as deleted
        };
      }
      return { ...item, deleted: true }; // Mark file or folder without children as deleted
    });
  }
  function updateDeletedStatusInHierarchy(folder: any, itemId: any) {
    if (folder.children) {
      folder.children = folder.children.map((child: any) => {
        if (child.id === itemId) {
          // Mark the item as deleted
          return { ...child, deleted: true };
        }
        if (child.type === "folder" && child.children) {
          // Recursively update nested folders
          return {
            ...child,
            children: updateDeletedStatusInHierarchy(child, itemId).children,
          };
        }
        return child;
      });
    }
    return folder;
  }

  async function handleDeleteSelected() {
    try {
      debugger;
      if (!selectedItems.length) {
        alert("No items selected for deletion.");
        return;
      }
      //const endpoint = `${clientAPIURL}api/ProjectRecords/upload`;
      const response = await fetch(`${clientAPIURL}api/ProjectRecords/bulkDelete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(selectedItems), // Send selectedItems as a JSON array
      });

      if (!response.ok) {
        throw new Error(`Failed to delete items: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result.message);






      const updatedVisibleItems = visibleItems.map((item) => {
        if (selectedItems.includes(item.id)) {
          if (item.type === "folder" && item.children) {
            return {
              ...item,
              deleted: true,
              children: markFolderAndChildrenDeleted(item.children), // Recursively delete children
            };
          }
          return { ...item, deleted: true }; // Mark file or standalone folder as deleted
        }
        return item; // Leave unchanged items
      });

      setVisibleItems(updatedVisibleItems);

      // Update the entire folder hierarchy
      let updatedCurrentFolder = { ...currentFolder };
      selectedItems.forEach((itemId) => {
        updatedCurrentFolder = updateDeletedStatusInHierarchy(
          updatedCurrentFolder,
          itemId
        );
      });
      setCurrentFolder(updatedCurrentFolder);

      // Update breadcrumbPath to reflect changes
      const updatedBreadcrumbPath = breadcrumbPath.map((folder) =>
        folder.id === currentFolder.id
          ? updatedCurrentFolder // Update the current folder
          : folder
      );
      setBreadcrumbPath(updatedBreadcrumbPath);

      setSelectedItems([]);
      setToastVisibility(true);
      setToastText("Selected items marked as deleted!");
    } catch (error) {
      console.error("Error updating deleted status:", error);
      setToastVisibility(true);
      setToastText("An error occurred while marking items as deleted.");
    }
  }

  async function __handleDeleteSelected() {
    try {
      const updatedItems = visibleItems.map((item) =>
        selectedItems.includes(item.id) ? { ...item, deleted: true } : item
      );
      setVisibleItems(updatedItems);
      setSelectedItems([]);
      setToastVisibility(true);
      setToastText("Selected items marked as deleted!");
    } catch (error) {
      console.error("Error updating deleted status:", error);
      setToastVisibility(true);
      setToastText("An error occurred while marking items as deleted.");
    }
  }
  async function _handleDeleteSelected() {
    try {
      for (const item of selectedItems) {
        // Simulate deleting each item with a 1-second delay
        const updatedItems = visibleItems.map((visibleItem) =>
          visibleItem.id === item ? { ...visibleItem, deleted: true } : visibleItem
        );

        setVisibleItems(updatedItems); // Re-render after marking each item as deleted
        setSelectedItems((prev) => prev.filter((id) => id !== item)); // Remove the current item from selection
        setToastVisibility(true);
        setToastText(`Item with ID ${item} marked as deleted!`);

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay for 1 second
      }

      setToastVisibility(true);
      setToastText("All selected items marked as deleted!");
    } catch (error) {
      console.error("Error updating deleted status:", error);
      setToastVisibility(true);
      setToastText("An error occurred while marking items as deleted.");
    }
  }
  async function handleConfirmDelete() {
    handleDeleteSelected();
    setShowConfirmation(false);
  };
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  function handleCancelDelete() {
    // Close the confirmation dialog without performing any action
    setShowConfirmation(false);
  };
  function handleDeletePress(sRows: any) {
    setShowConfirmation(true);
    console.log("Delete Pressed, showConfirmation:", showConfirmation); // Debug log
  }
  const downloadFile = async (blobId: string, fileName: string) => {
    try {
      debugger;
      const endpoint = `${clientAPIURL}api/ProjectRecords/download/${blobId}`;
      //const endpoint = `https://localhost:44346/api/ProjectRecords/download/${blobId}`;
      const accessToken = await getAccessToken();

      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor to download the file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the suggested file name
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file. Please try again.");
    }
  };

  return (
    <View style={styles.root}>
      {showConfirmation && (
        <View>
          <ConfirmBox
            title={"Confirmation"}
            message={"Are you sure you want to proceed to delete selected items?"}
            yesCallback={handleConfirmDelete}
            noCallback={handleCancelDelete}
          />
        </View>
      )}
      {showFolderModal && (
        // <View style={{ position: "absolute", top: "30%", left: "10%", right: "10%", backgroundColor: "white", padding: 20, borderRadius: 10 }}>
        //   <Text>Enter Folder Name:</Text>
        //   <TextInput
        //     value={newFolderName}
        //     onChangeText={setNewFolderName}
        //     style={{ borderBottomWidth: 1, marginVertical: 10 }}
        //   />
        //   <ScrollView horizontal style={styles.ribbonScrollView}>
        //     <View style={styles.buttonContainer}>
        //       <TouchableOpacity
        //         onPress={createNewFolder}
        //         style={[styles.button, newFolderName !=null ? null : styles.disabledButton]}
        //         disabled={false}
        //       >
        //         <Text style={styles.buttonText}>Create</Text>
        //       </TouchableOpacity>
        //     </View>
        //     <View style={styles.buttonDivider}></View>
        //     <View style={styles.buttonContainer}>
        //       <TouchableOpacity
        //         onPress={() => setShowFolderModal(false)}
        //         style={[styles.button]}
        //         disabled={false}
        //       >
        //         <Text style={styles.buttonText}>Close</Text>
        //       </TouchableOpacity>
        //     </View>
        //   </ScrollView>
        // </View>
        <CreateFolderModal
          showFolderModal={showFolderModal}
          setShowFolderModal={setShowFolderModal}
          newFolderName={newFolderName}
          setNewFolderName={setNewFolderName}
          createNewFolder={createNewFolder}
          updateFolder={updateFolder}
          isEditing={isEditing}
          fileExtension={fileExtension}
        />
      )}


      <ScrollView horizontal style={styles.ribbonScrollView}>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            onPress={() => setShowFolderModal(true)}
            style={[styles.button, props.disabled && styles.disabledButton]}
            disabled={false}
          >
            <Text style={styles.buttonText}>New Folder</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.buttonDivider}></View>

        <View style={styles.buttonContainer}>
          <TouchableOpacity
            onPress={handleUploadClick}
            style={[styles.button, props.disabled && styles.disabledButton]}
            disabled={false}
          >
            <Text style={styles.buttonText}>Upload</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.buttonDivider}></View>
        {props.logTitle !== "Report Request" && (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={openEditFolderModal}
              style={selectedItems.length !== 1 ? styles.disabledButton : styles.button}
              disabled={selectedItems.length !== 1} // Ensure disabled state logic is correct
            >
              <Text style={styles.buttonText}>Rename</Text>
            </TouchableOpacity>
          </View>
        )}

        <View style={styles.buttonDivider}></View>

        {props.logTitle !== "Report Request" && (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={() => {
                console.log("Delete button clicked"); // Debug log
                setShowConfirmation(true);
              }}
              style={selectedItems.length === 0 ? styles.disabledButton : styles.button}
              disabled={selectedItems.length === 0} // Ensure disabled state logic is correct
            >
              <Text style={styles.buttonText}>Delete</Text>
            </TouchableOpacity>
          </View>
        )}

      </ScrollView>

      {/* Breadcrumb Navigation */}
      <View style={styles.breadcrumbContainer}>
        {breadcrumbPath.map((folder: any, index: number) => (
          <React.Fragment key={folder.id}>
            <TouchableOpacity
              onPress={() => navigateToBreadcrumb(index)}
              style={styles.breadcrumbItem}
            >
              <Text style={styles.breadcrumbText}>{folder.name}</Text>
            </TouchableOpacity>
            {index < breadcrumbPath.length - 1 && (
              <Text style={styles.breadcrumbSeparator}> → </Text>
            )}
          </React.Fragment>
        ))}
      </View>

      <ProjectRecordUploadModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={handleFileUpload}
      />

      {(
        <FlatList
          data={visibleItems.filter((item) => !item.deleted)}
          keyExtractor={(item) => item.id}
          ListHeaderComponent={() => (
            <View style={styles.headerRow}>
              <Text style={styles.headerCellCheckbox}></Text> {/* Space for checkbox column */}
              <TouchableOpacity
                style={styles.headerCellName}
                onPress={() => handleSort("name")}
              >
                <Text style={styles.headerText}>
                  Name{" "}
                  {sortConfig.column === "name" &&
                    (sortConfig.order === "asc" ? "▲" : "▼")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.headerCell}
                onPress={() => handleSort("dateCreated")}
              >
                <Text style={styles.headerText}>
                  Created{" "}
                  {sortConfig.column === "dateCreated" &&
                    (sortConfig.order === "asc" ? "▲" : "▼")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.headerCell}
                onPress={() => handleSort("dateModified")}
              >
                <Text style={styles.headerText}>
                  Modified{" "}
                  {sortConfig.column === "dateModified" &&
                    (sortConfig.order === "asc" ? "▲" : "▼")}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          renderItem={({ item }) => (
            <View style={styles.row}>
              {/* Checkbox for selection */}
              <Checkbox.Android
                status={selectedItems.includes(item.id) ? "checked" : "unchecked"}
                onPress={() => toggleSelection(item.id)}
                uncheckedColor="#666"
                color="#007BFF"
              />
              {/* File/Folder Name */}
              <TouchableOpacity
                onPress={() => {
                  if (item.type === "folder") {
                    openFolder(item); // Open folder if type is "folder"
                  } else {
                    //downloadFile(item.blobId, item.name);
                    toggleSelection(item.id);
                  }
                }}
                style={styles.cellNameContainer}
              >
                {
                  item.type === "folder" ?
                    <Text style={[styles.cellName]} onPress={() => { openFolder(item); }}>
                      {"📁"} {item.name}
                    </Text>
                    :
                    <Text style={[styles.cellName, item.type === "file" && styles.hyperlink]} onPress={() => { downloadFile(item.blobId, item.name) }}>
                      {"📄"} {item.name}
                    </Text>
                }
              </TouchableOpacity>
              {/* Dates */}

              <TouchableOpacity
                onPress={() => {
                  toggleSelection(item.id);
                }}
                style={styles.cellNameContainer}
              >
                <Text style={styles.cell}>
                  {item.dateCreated}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  toggleSelection(item.id);
                }}
                style={styles.cellNameContainer}
              >
                <Text style={styles.cell}>
                  {item.dateModified}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          ListFooterComponent={
            hasMore ? (
              <TouchableOpacity
                style={styles.loadMoreButton}
                onPress={loadMoreItems}
              >
                {loading ? (
                  <ActivityIndicator size="small" color="#fff" />
                ) : (
                  <Text style={styles.loadMoreText}>Load More</Text>
                )}
              </TouchableOpacity>
            ) : (
              <Text style={styles.noMoreText}>No more items to load</Text>
            )
          }
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 10,
  },
  breadcrumbContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  breadcrumbItem: {
    paddingHorizontal: 5,
  },
  breadcrumbText: {
    fontSize: 14,
    color: "#007BFF",
  },
  breadcrumbSeparator: {
    fontSize: 14,
    color: "#000",
  },
  headerRow: {
    flexDirection: "row",
    backgroundColor: "#f1f1f1",
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  row: {
    flexDirection: "row",
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  cellName: {
    flex: 2,
    fontSize: 14,
  },
  cell: {
    flex: 1,
    fontSize: 14,
  },

  cellText: {
    fontSize: 16,
    color: "#333"
  },
  headerCell: {
    flex: 1,
    fontWeight: "bold",
    fontSize: 16,
    paddingHorizontal: 8
  },
  headerCellName: {
    flex: 2,
    fontWeight: "bold",
    fontSize: 16,
    paddingHorizontal: 8
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#000"
  },
  loadMoreButton: {
    padding: 15,
    backgroundColor: "#007BFF",
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  loadMoreText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  noMoreText: {
    textAlign: "center",
    marginVertical: 10,
    fontSize: 14,
    color: "#555",
  },
  ribbonScrollView: {
    flexDirection: "row",
    backgroundColor: "#f4f4f4",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 5,
  },
  button: {
    backgroundColor: "#0078D4", // Default button color
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    minWidth: 100,
    alignItems: "center",
  },
  disabledButton: {
    backgroundColor: "gray", //"#676767", // Disabled button color
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    minWidth: 100,
    alignItems: "center",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "600",
    fontSize: 14,
  },
  buttonDivider: {
    width: 1,
    backgroundColor: "#ddd",
    marginHorizontal: 5,
  },
  headerCellCheckbox: {
    width: 40,
    paddingHorizontal: 5,
  },
  cellNameContainer: {
    flex: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  hyperlink: {
    color: "blue", // Hyperlink color
    textDecorationLine: "underline", // Underline for file names
  },
});

import React, { useEffect, useState } from 'react';
import { View, ScrollView, Platform } from 'react-native';
import { VictoryPie, VictoryLabel } from 'victory-native';
import Legend from './Legend';
import ChartStyles from './chartStyles';

interface PieChartProps {
    data: {
        name: string;
        value: number;
        color: string;
    }[];
    donutRadius?: number;
    containerWidth?: number;
    legendFontColor?: string;
}

export default function FencelinePieChartComp(props: PieChartProps) {
    const [pieData, setPieData] = useState([]);
    const [colors, setColors] = useState<string[]>([]);

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            setPieData(
                props.data.map((item) => ({
                    x: item.name,
                    y: item.value,
                }))
            );

            setColors(props.data.map((item) => item.color));
        } else {
            setPieData([]);
        }
    }, [props.data]);

    const hasData = pieData.length > 0;

    return (
        <View style={{ flexDirection: 'column', height: 'auto' }}>
            <VictoryPie
                animate={{
                    duration: 1000,
                }}
                colorScale={hasData ? colors : ['#f0f0f0']}
                innerRadius={props.donutRadius ? 30 : 50}
                style={{ labels: { display: 'none' } }}
                height={Platform.OS === 'web' ? 250 : 280}
                data={hasData ? pieData : [{ x: 'No Data', y: 1 }]}
            />
            {!hasData && (
                <VictoryLabel
                    text="No data available"
                    x={Platform.OS === 'web' ? 200 : 140} // Center the text
                    y={Platform.OS === 'web' ? 200 : 140}
                    textAnchor="middle"
                    style={{ fontSize: 16, fill: 'gray' }}
                />
            )}
            {hasData && (
                <View
                    style={{
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 10,
                        marginLeft:'25%'
                    }}
                >
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        horizontal
                        contentContainerStyle={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {props.data.map((item, index) => (
                            <View key={index} style={{ marginHorizontal: 10 }}>
                                <Legend
                                    fontColor={props.legendFontColor || '#000'}
                                    name={`${item.name}: ${item.value}`}
                                    color={item.color}
                                />
                            </View>
                        ))}
                    </ScrollView>
                </View>
            )}
        </View>
    );
};
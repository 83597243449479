import React from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Modal,
  StyleSheet,
} from "react-native";

interface CreateFolderModalProps {
  showFolderModal: boolean; // Indicates if the modal is visible
  setShowFolderModal: (value: boolean) => void; // Function to toggle modal visibility
  newFolderName: string; // Name of the folder being created
  setNewFolderName: (value: string) => void; // Function to update the folder name
  createNewFolder: () => void; // Function to handle folder creation
  updateFolder?: () => void; // Function to handle folder updates
  isEditing?: boolean; // Indicates if the modal is used for editing
  fileExtension?: string
}
export default function CreateFolderModal({
  showFolderModal,
  setShowFolderModal,
  newFolderName,
  setNewFolderName,
  createNewFolder,
  isEditing,
  updateFolder,
  fileExtension
}: CreateFolderModalProps) {

  const closeModal = () => {
    setNewFolderName(""); // Reset the folder name
    setShowFolderModal(false); // Close the modal
  };

  return (
    <Modal
      visible={showFolderModal}
      transparent={true}
      animationType="fade"
      onRequestClose={() => closeModal}
    >
      <View style={styles.overlay}>
        <View style={styles.modalContainer}>
          <Text>{isEditing ? "Edit Folder Name" : "Enter Folder Name"}</Text>
          <View
            style={{
              flexDirection: 'row', // Arrange input and extension text in a row
              alignItems: 'center', // Vertically align input and extension text
              justifyContent: 'center', // Center-align within the modal
              width: '100%', // Make the container take the full modal width
              paddingHorizontal: 20, // Add some horizontal padding for spacing
              marginVertical: 10, // Add spacing between the input and other elements
            }}
          >
            <TextInput
              value={newFolderName}
              onChangeText={setNewFolderName}
              style={{
                flex: 1, // Make the input field take up available space
                borderWidth: 1,
                borderColor: '#ccc',
                borderRadius: 5,
                padding: 10,
                fontSize: 16,
              }}
              placeholder="Enter name"
            />
            {fileExtension && (
              <Text style={{
                marginLeft: 10, // Add some spacing between the input field and extension
                fontSize: 16,
                color: '#555', // Optional: Slightly dim the extension color
              }}
              >
                .{fileExtension}
              </Text>
            )}
          </View>
          <View style={styles.buttonRow}>
            <TouchableOpacity
              onPress={isEditing ? updateFolder : createNewFolder}
              style={[
                styles.button,
                newFolderName.trim() ? null : styles.disabledButton,
              ]}
              disabled={!newFolderName.trim()}
            >
              <Text style={styles.buttonText}>
                {isEditing ? "Update" : "Create"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={closeModal}
              style={styles.button}
            >
              <Text style={styles.buttonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    width: "70%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 30,
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  textInput: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    marginBottom: 20,
    padding: 5,
  },
  buttonRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    flex: 1,
    padding: 10,
    marginHorizontal: 5,
    backgroundColor: "#007BFF",
    borderRadius: 5,
    alignItems: "center",
  },
  disabledButton: {
    backgroundColor: "#ccc",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
});

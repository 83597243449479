async function getData(endpoint: string, accessToken:string) {
  try {
    debugger
    const finalUri = endpoint
    const response = await fetch(finalUri, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'cache':'force-cache'
      },
    });
    return response;
  }
  catch (error) {
    console.error(error);
  }
}

async function getRecordById(endpoint: string, accessToken:string) {
  try {
    const finalUri = endpoint
    const response = await fetch(finalUri, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'cache':'force-cache'
      },
    });
    return response;
  }
  catch (error) {
    console.error(error);
  }
}
async function createRecord(endpoint: string, accessToken:string, data:any) {
  debugger;
  try {
    const response = await fetch(`${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Failed to create folder. Status: ${response.status}`);
    }
    return response;
  }
  catch (error) {
    console.error(error);
  }
}
async function updateRecord(endpoint: string, accessToken:string, data:any) {
  debugger;
  try {
    const response = await fetch(`${endpoint}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`Failed to create folder. Status: ${response.status}`);
    }
    return response;
  }
  catch (error) {
    console.error(error);
  }
}

async function deleteRecord(imageFileName: string, accessToken: string, clientAPIURL: string) {
  try {
    const finalUri = clientAPIURL + "api/getAsset/" + imageFileName;
    const response = await fetch(finalUri, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'cache':'force-cache'
      },
    });
    return response;
  }
  catch (error) {
    console.error(error);
  }
}

export { getData, createRecord, deleteRecord, getRecordById, updateRecord };

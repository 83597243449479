import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, Pressable } from 'react-native';
import SingleBarChartComp from '../charts/fenceline_barChart_builder';
import MultiBarChartComp from '../charts/fenceline_barChart_builder_multiple';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { logStyles } from '../../LogControllers/LogStyles';
import StackedBarChartComp from '../charts/fencelineStackedBarChartComp';
import FencelinePieChartComp from '../charts/fencelinePieChartComp';

interface Props {
    data: any;
    sixMonthData: any;
    threshold?: number;
}
interface StationData {
    stationName: string;
    recordedDate: string;
    pM2_5: number;
}

export default function FencelinePM2P5(props: Props) {
    const [dailyData, setDailyData] = useState<StationData[]>([]);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [pm2_5DailychartData, setPM2_5DailyChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [labels, setLabels] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = props.data
            setDailyData(data);
            processChartData(data);
            const multiBarChartData = processMultiBarChartData(data); // Call the new processor
            setPM2_5DailyChartData(multiBarChartData);
        } catch (error) {
            console.error('Error in processing PM2_5 data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData(); // Fetch initial data on component mount
    }, []);
    const groupDataByDate = (data: StationData[]) => {
        return data.reduce((acc: Record<string, StationData[]>, item: StationData) => {
            const date = item.recordedDate.split("T")[0]; // Extract date
            if (!acc[date]) acc[date] = [];
            acc[date].push(item);
            return acc;
        }, {});
    };
    const processChartData = (data: any) => {
        const groupedByDate = groupDataByDate(data);
        // Extract labels (dates)
        const pm2_5Data: any = { MaxPM2_5: [] };
        const labels: string[] = [];
        Object.entries(groupedByDate).forEach(([date, items]) => {
            const maxPM2_5 = Math.max(...items.map((item: any) => item.pM2_5));
            pm2_5Data["MaxPM2_5"].push(maxPM2_5);
            labels.push(date);
        });
        // Prepare datasets for GroupBarChartsComp
        const colorScaleBar1 = scaleOrdinal(schemeCategory10);
        const BarDatasets1 = Object.entries(pm2_5Data).map(([key, values], index: any) => ({
            data: values,
            strokeWidth: 2,
            color: (opacity = 1) => colorScaleBar1(index) + Math.round(opacity * 255).toString(16),
        }));
        const PM2_5MaxBar = {
            labels, // Dates
            datasets: BarDatasets1,
        };
        setChartData(PM2_5MaxBar);
    };
    const processMultiBarChartData = (data: StationData[]) => {
        const groupedByDate = groupDataByDate(data);
        const uniqueStations = Array.from(new Set(data.map((item) => item.stationName)));
        const labels: string[] = Object.keys(groupedByDate); // Unique dates
        const colorScaleBar1 = scaleOrdinal(schemeCategory10);
        const multiBarDatasets = uniqueStations.map((stationName, index: any) => ({
            name: stationName, // Group name for the station
            data: labels.map((date) => {
                const items = groupedByDate[date];
                const stationData = items.find((item) => item.stationName === stationName);
                return {
                    x: date, // Date
                    y: stationData ? stationData.pM2_5 : 0, // PM2_5 value or 0 if no data
                };
            }),
            strokeWidth: 2,
            color: (opacity = 1) => colorScaleBar1(index) + Math.round(opacity * 255).toString(16),
        }));
        return {
            labels, // Dates
            datasets: multiBarDatasets, // Data for each station per date
        };
    };
    const processExceedancesData = (sixMonthData: StationData[], threshold: number) => {
        // Group data by month-year
        const groupedByMonthYear = sixMonthData.reduce((acc: Record<string, { exceedances: number; nonExceedances: number }>, item) => {
            const date = new Date(item.recordedDate);
            const monthYear = `${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;
            if (!acc[monthYear]) {
                acc[monthYear] = { exceedances: 0, nonExceedances: 0 };
            }

            if (item.pM2_5 > threshold) {
                acc[monthYear].exceedances += 1;
            } else {
                acc[monthYear].nonExceedances += 1;
            }

            return acc;
        }, {});

        // Prepare chart data
        const labels = Object.keys(groupedByMonthYear); // Month-Year labels
        const exceedances = labels.map((label) => groupedByMonthYear[label].exceedances);
        const nonExceedances = labels.map((label) => groupedByMonthYear[label].nonExceedances);

        // Build datasets for bar chart
        const colorScaleBar1 = scaleOrdinal(schemeCategory10);
        const datasets = [
            {
                name: 'Non-Exceedances',
                data: nonExceedances.map((value, index) => ({ x: labels[index], y: value })),
                strokeWidth: 2,
                color: (opacity = 1) => `rgba(0, 128, 0, ${opacity})`, // Green
            },
            {
                name: 'Exceedances',
                data: exceedances.map((value, index) => ({ x: labels[index], y: value })),
                strokeWidth: 2,
                color: (opacity = 1) => `rgba(255, 69, 0, ${opacity})`, // Orange/Red
            },
        ];

        return {
            labels,
            datasets,
        };
    };
    const processPieChartData = (sixMonthData: StationData[], threshold: number) => {
        const totalExceedances = sixMonthData.filter((item) => item.pM2_5 > threshold).length;
        const totalNonExceedances = sixMonthData.length - totalExceedances;

        // Define colors for exceedances and non-exceedances
        const colorScale = {
            exceedances: '#FF6347', // Tomato red
            nonExceedances: '#32CD32', // Lime green
        };

        // Prepare input for the pie chart
        return [
            { name: 'Exceedances', value: totalExceedances, color: colorScale.exceedances },
            { name: 'Non-Exceedances', value: totalNonExceedances, color: colorScale.nonExceedances },
        ];
    };
    const exceedancesChartData = processExceedancesData(props.sixMonthData, props.threshold);
    const pieChartData = dailyData && processPieChartData(dailyData, props.threshold);
    return (
        <View>
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
                <View>
                    <ScrollView contentContainerStyle={styles.container}>
                        {dailyData.length > 0 ? (
                            <View>
                                <ScrollView style={styles.container} contentContainerStyle={logStyles.scrollViewContentContainer}>
                                    <Text style={styles.title}>PM2_5 Max (Daily)</Text>
                                    <View style={[logStyles.fencelineCard, { backgroundColor: '' }]}>
                                        {chartData && <SingleBarChartComp threshold={props.threshold} data={chartData} label={labels} ChartTitle={"PM2_5 Daily Max"} Type={'Single'} />}
                                    </View>
                                </ScrollView>
                                <ScrollView style={styles.container} contentContainerStyle={logStyles.scrollViewContentContainer}>
                                    <Text style={styles.title}>PM2_5 by Station (Daily)</Text>
                                    <View style={[logStyles.fencelineCard, { backgroundColor: '' }]}>
                                        {pm2_5DailychartData && <MultiBarChartComp threshold={props.threshold} data={pm2_5DailychartData} label={pm2_5DailychartData.labels} ChartTitle={"PM2_5 by Station (Daily)"} Type={'Multiple'} />}
                                    </View>
                                </ScrollView>
                                <View style={styles.rowContainer}>
                                    {/* Stacked Bar Chart */}
                                    <View style={styles.chartContainer}>
                                        <Text style={styles.title}>PM2.5 Exceedances (Last 6 Months)</Text>
                                        <View style={[logStyles.fencelineCard, { backgroundColor: '' }]}>
                                            {exceedancesChartData && (
                                                <StackedBarChartComp
                                                    data={exceedancesChartData}
                                                    label={exceedancesChartData.labels}
                                                    ChartTitle={"PM10 Monthly Exceedances vs Non-Exceedances"}
                                                />
                                            )}
                                        </View>
                                    </View>

                                    {/* Pie Chart */}
                                    <View style={styles.chartContainer}>
                                        <View style={[{width:600}]}>
                                        <Text style={styles.title}>PM2.5 Exceedances (Reporting Period)</Text>
                                            {pieChartData && (
                                                <FencelinePieChartComp
                                                    data={pieChartData}
                                                    donutRadius={50}
                                                    containerWidth={100}
                                                    legendFontColor="#000"
                                                />
                                            )}
                                        </View>
                                    </View>
                                </View>
                                {/* <ScrollView style={styles.container} contentContainerStyle={logStyles.scrollViewContentContainer}>
                                    <Text style={styles.title}>PM2_5 Exceedances (Last 6 Months)</Text>
                                    <View style={[logStyles.fencelineCard, { backgroundColor: '' }]}>
                                        {exceedancesChartData && (
                                            <StackedBarChartComp
                                                data={exceedancesChartData}
                                                label={exceedancesChartData.labels}
                                                ChartTitle={"PM2_5 Monthly Exceedances vs Non-Exceedances"}
                                                
                                            />
                                        )}
                                    </View>
                                </ScrollView> */}
                            </View>
                        ) : (
                            <Text style={styles.noDataText}>
                                {loading ? 'Loading data...' : 'No data available for the selected range.'}
                            </Text>
                        )}
                    </ScrollView>
                </View>
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    rowContainer: {
        flexDirection: 'row', // Horizontal layout
        flexWrap: 'wrap', // Allow wrapping for smaller screens
        justifyContent: 'space-between', // Add space between charts
        paddingHorizontal: 10, // Add some padding
    },
    chartContainer: {
        flex: 1, // Each chart takes half the screen width
        maxWidth: '48%', // Limit the maximum width of each chart to 48%
        marginBottom: 20, // Add space below in case of wrapping
    },
    flexItem: {
        flexBasis: '30%', // Adjust to control the size of each item in the row
        marginBottom: 8, // For smaller screens when wrapping
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    datePicker: {
        width: '100%',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    button: {
        borderRadius: 5,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#4BB75F',
    },
    buttonClose: {
        backgroundColor: '#4BB75F',
    },
    dataContainer: {
        marginTop: 16,
    },
    dataRow: {
        padding: 8,
        marginBottom: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
    },
    noDataText: {
        marginTop: 16,
        fontSize: 16,
        fontStyle: 'italic',
        color: '#999',
    },

    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10,
    },
});
